<template>
  <div id="public-app" class="min-h-screen text-black bg-white">
    <nav class="flex items-center w-full h-20 px-5 mx-auto my-0 gap-x-3 max-w-7xl">
      <router-link class="flex mr-auto hover:opacity-60" to="/">
        <img src="/img/logo.png" alt="Desktivity" height="48" width="164"/>
        <span class="mt-2 -ml-px text-xs font-semibold text-red-500 select-none">BETA</span>
      </router-link>
      <router-link class="hidden py-3 bg-purple-600 rounded-lg btn lg:block" to="/register">Free Sign Up</router-link>
      <router-link class="py-3 rounded-lg btn outline" to="/login">Login</router-link>
    </nav>
    <main>
      <router-view></router-view>
    </main>
    <footer class="w-full mt-40">
      <div class="container max-w-5xl py-12 mx-auto">
        <div class="flex flex-col-reverse lg:flex-row">
          <div class="mx-auto lg:text-left lg:w-1/2">
            <p class="text-gray-500">Desktivity © 2021</p>
          </div>
          <div class="mx-auto mb-4 lg:text-right lg:w-1/2 lg:mb-0">
            <a href="https://twitter.com/desktivity" class="inline-block px-2 mx-4">Twitter</a>
            <a href="https://instagram.com/desktivity" class="inline-block px-2 mx-4">Instagram</a>
            <a href="mailto:desktivity@gmail.com" class="inline-block px-2 mx-4 lg:mr-0">Email</a>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'publicapp'
}
</script>
